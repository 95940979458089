import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";

export enum UpdateRemarksReferenceType {
    LOAD = 1,
    LORRY = 2,
    LOAD_BID = 5,
    LORRY_BID = 6
}

export interface UpdateRemarksPayload {
    root_reference_type: UpdateRemarksReferenceType;
    root_reference_id: number;
    reference_type: UpdateRemarksReferenceType;
    reference_id: number;
    disposition?: string;
    sub_disposition?: string;
    other?: string;
    source?: string;
    disposition_id?: number;
    status_id?: number;
    closing_id?: number;
    ticket_type?: number;
    event_source?: string;
}

interface UpdateRemarksResponse {
    disposition?: string;
    sub_disposition?: string;
    other?: string;
}

export const useUpdateRemarks = (option?: Parameters<typeof useVahakMutationQuery>[2]) => {
    const { POST } = useVahakHttpClient();
    function updateRemarks(data: UpdateRemarksPayload) {
        const payload: UpdateRemarksPayload = {
            ...data,
            other: data?.other?.replace(/\s+/g, " ")?.trim()
        };
        return POST<UpdateRemarksPayload, UpdateRemarksResponse>(API.UPDATE_REMARKS, payload);
    }

    return useVahakMutationQuery<UpdateRemarksResponse, unknown, UpdateRemarksPayload>(
        [QueryNames.updateRemarks],
        updateRemarks,
        option
    );
};
